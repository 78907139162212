<template>
  <div class="d-flex justify-content-center align-items-center" style="height: calc(100vh - 84px);">
  <div>
    <div style="display: flex; justify-content: center; width: 100%">
      <img src="/logo.png" height="25" class="mb-4" />
    </div>
    <div class="card card-md">
      <form class="card-body" @submit.prevent="initSignin">
        <h2 class="card-title text-center mb-4">Login to your account</h2>
        <div class="mb-3" v-if="!twoFactor">
          <label class="form-label" style="text-align: left">Email</label>
          <input
            v-model="user.email"
            type="email"
            class="form-control"
            placeholder="Enter email"
            autocomplete="email"
          />
        </div>
        <div class="mb-3" v-if="!twoFactor">
          <label class="form-label" style="text-align: left">Password</label>
          <input
            v-model="user.password"
            type="password"
            class="form-control"
            placeholder="Password"
            autocomplete="password"
          />
        </div>

        <div class="mb-3" v-if="twoFactor">
          <label class="form-label" style="text-align: left">Code from authenticator or recovery code</label>
          <input
            v-model="user.code"
            type="tel"
            class="form-control"
            placeholder="Code from authenticator or recovery code"
            autocomplete="one-time-code"
            name="totp"
            id="totp"
            inputmode="numeric"
            pattern="[0-9]*"
          />
        </div>
        <vue-recaptcha
          v-show="!twoFactor"
          ref="recaptcha"
          class="g-recaptcha"
          :sitekey="sitekey"
          :loadRecaptchaScript="true"
          @verify="verify"
          @expired="expired"
        ></vue-recaptcha>
        <div class="form-footer">
          <button
            :disabled="(!user.email || !user.password || isRobot) && !twoFactor"
            type="submit"
            class="btn btn-primary w-100"
          >
            Signin
          </button>
        </div>
      </form>
    </div>
    <div class="text-center text-muted mt-3">
      Do not have account? <router-link to="/signup"> Sign up </router-link>
    </div>
    <div class="text-center text-muted mt-3">
      Forgot password?
      <router-link to="/password-recovery"> Recover Password </router-link>
    </div>
  </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
	name: 'Signin',
	data () {
		return {
			sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
			user: {
				email: null,
				password: null,
				recaptcha: null,
				code: ''
			},
			isRobot: true,
			twoFactor: false
		}
	},
	components: {
		VueRecaptcha
	},
	computed: {
		...mapState({
			origin: (state) => state.origin
		})
	},
	mounted () {},
	methods: {
		...mapActions(['signin']),
		...mapMutations(['loginSuccess']),
		verify (response) {
			if (response) {
				this.isRobot = false
				this.user.recaptcha = response
			} else {
				this.isRobot = true
				this.user.recaptcha = null
			}
		},
		expired () {
			this.isRobot = true
			this.user.recaptcha = null
		},
		initSignin () {
      if ((!this.user.email || !this.user.password || this.isRobot) && !this.twoFactor) {
        return
      }
			if (this.isRobot && !this.twoFactor) {
				this.$swal.fire({
					icon: 'error',
					text: 'Please verify that you are not a robot!'
				})
				return
			}
			if (!this.user.email || !this.user.password) {
				this.$swal.fire({
					icon: 'error',
					text: 'Please enter username and password.'
				})
				return
			}
			this.signin(this.user)
				.then((data) => {
					if (
						data.Status === 'ok' &&
            data.Data === 'Enter Two Factor code'
					) {
						if (this.twoFactor) {
							this.$swal.fire({
								icon: 'error',
								text: 'Please enter two factor code.'
							})
						} else {
							this.twoFactor = true
						}
						this.expired()
						this.$refs.recaptcha.reset()
					} else if (data.Status === 'ok') {
						this.loginSuccess(data.Data)
						if (this.origin) {
							window.location.replace(window.location.protocol + '//' + this.origin + '/sso')
						} else {
							this.$router.push('/')
						}
					}
				})
				.catch((err) => {
					if (err?.response?.data?.Data === 'account blocked') {
						this.$router.push('/email-verification')
						return
					}
					this.$swal.fire({
						icon: 'error',
						text: err?.response?.data?.Data
					})
				})
		}
	}
}
</script>
<style scoped>
.g-recaptcha {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
</style>
