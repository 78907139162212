import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'bootstrap/dist/js/bootstrap.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@tabler/core/dist/css/tabler.css'
import '@tabler/core/dist/js/tabler'

import initIconLibrary from '@/iconLibrary'

initIconLibrary()

createApp(App)
	.use(store)
	.use(router)
	.use(VueSweetalert2)
	.component('font-awesome-icon', FontAwesomeIcon)
	.mount('#app')
