import {
	library
} from '@fortawesome/fontawesome-svg-core'

import {
	faSun,
	faMoon
} from '@fortawesome/free-solid-svg-icons'

const initIconLibrary = function () {
	library.add(faSun)
	library.add(faMoon)
}

export default initIconLibrary
