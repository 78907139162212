<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Authentication Tokens</h3>
    </div>
    <div class="card-body p2">
      <span style="float: left" class="mb-2">Tokens:</span>
      <div v-if="tokens">
        <div class="input-group mb-3" v-for="token of tokens" :key="token.ID">
          <input
            type="text"
            class="form-control"
            :placeholder="token.Name"
            disabled
          />
          <button
            class="btn btn-outline-danger"
            type="button"
            @click="initRemoveToken(token)"
          >
            Remove
          </button>
        </div>
      </div>
      <button
        class="btn btn-outline-success"
        style="width: 100%"
        type="button"
        v-if="!createToken"
        @click="createToken = true"
      >
        Add new token
      </button>
      <div v-else>
        <span>Create new token</span>
        <input
          type="text"
          class="form-control mb-1 mt-2"
          placeholder="Enter Token Name..."
          :disabled="token"
          v-model="tokenName"
        />
        <button
          class="btn btn-outline-success"
          style="width: 100%"
          type="button"
          @click="initTokenCreate()"
          v-if="!token"
        >
          Create token
        </button>
        <div v-if="token" style="text-align:center" class="mt-1">
          <span>Token was successfully created!</span><br />
          <span style="color: red">
            Please copy as token will disappear after page reload.
          </span>
          <pre>{{ token }}</pre>
          <button class="btn btn-outline-warning" @click="resetForm()">I have copied</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
	data () {
		return {
			createToken: false,
			tokenName: '',
			token: null,
			tokens: []
		}
	},
	methods: {
		...mapActions(['tokenCreate', 'getTokens', 'removeToken']),
		resetForm () {
			this.tokenName = ''
			this.token = null
			this.createToken = false
		},
		initTokenCreate () {
			this.tokenCreate({ Name: this.tokenName }).then((data) => {
				if (data.Status === 'ok') {
					this.token = data.Data
					this.initGetTokens()
				}
			})
		},
		initGetTokens () {
			this.getTokens().then((data) => {
				if (data.Status === 'ok') {
					this.tokens = data.Data
				}
			})
		},
		initRemoveToken (token) {
			this.removeToken(token).then((data) => {
				if (data.Status === 'ok') {
					this.initGetTokens()
				}
			})
		}
	},
	mounted () {
		this.initGetTokens()
	}
}
</script>
