import { createRouter, createWebHistory } from 'vue-router'
import Signin from '../views/Signin.vue'
import Signup from '../views/Signup.vue'
import EmailVerification from '../views/EmailVerification.vue'
import EmailVerificationCode from '../views/EmailVerificationCode.vue'
import PasswordRecovery from '../views/PasswordRecovery.vue'
import admin from '../views/admin.vue'
import Origin from '../views/Origin.vue'
import Home from '../views/Home.vue'
import Settings from '../views/Settings.vue'
import AccountInfo from '../components/Settings/AccountInfo.vue'
import Keys from '../components/Settings/Keys.vue'
import Tokens from '../components/Settings/Tokens.vue'
import TwoFa from '../components/Settings/TwoFa.vue'
import Organizations from '../components/Settings/Organizations.vue'
import SecurityLogs from '../components/Settings/SecurityLogs.vue'
import OriganizationSettings from '../components/Settings/OrganizationSettings.vue'
import store from '../store'

const routes = [
	{
		path: '/origin',
		name: 'Origin',
		component: Origin
	},
	{
		path: '/signin',
		name: 'Signin',
		component: Signin
	},
	{
		path: '/signup',
		name: 'Signup',
		component: Signup
	},
	{
		path: '/password-recovery',
		name: 'PasswordRecovery',
		component: PasswordRecovery
	},
	{
		path: '/email-verification',
		name: 'EmailVerification',
		component: EmailVerification
	},

	{
		path: '/email-verification-code/:code',
		name: 'EmailVerificationCode',
		component: EmailVerificationCode
	},
	{
		path: '/admin',
		name: 'admin',
		component: admin
	},
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/settings',
		name: 'SettingsMain',
		component: Settings,
    children: [
      {
        path: '',
        name: 'SettingsAccount',
        component: AccountInfo
      },
      {
        path: 'keys',
        name: 'SettingsKeys',
        component: Keys
      },
      {
        path: 'tokens',
        name: 'SettingsTokens',
        component: Tokens
      },
      {
        path: 'twofa',
        name: 'SettingsTwoFa',
        component: TwoFa
      },
      {
        path: 'organizations',
        name: 'SettingsOrganizations',
        component: Organizations
      },
      {
        path: 'securitylogs',
        name: 'SettingsSecurityLogs',
        component: SecurityLogs
      },
      {
        path: 'organization/:id',
        name: 'SettingsOrganizationSettings',
        component: OriganizationSettings
      }
    ]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	const loggedIn = store.state.user
	if ((!loggedIn && to.path === '/admin') || (!loggedIn && to.path === '/')) {
		next({
			name: 'Signin'
		})
	} else {
		next()
	}
})

export default router
