<template>
  <div class="page-body">
    <input placeholder="Search by email or role name" class="form-control mb-2" type="text" v-model="search" />
    <div class="card">
      <div class="table-responsive" style="overflow: visible">
        <table class="table card-table">
          <thead>
            <tr style="border-bottom: 1px solid #000;">
              <th>Email</th>
              <th>Role</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user of usersToRender" :key="user.ID">
              <td>
                <div>
                  {{ user.Email }}
                </div>
              </td>
              <td>
                <div class="dropdown d-inline-block">
                  <a href="#" class="btn dropdown-toggle" data-bs-toggle="dropdown">
                    Role(s) <span class="badge bg-green mx-2">{{user.Role?.length || 0}}</span>
                  </a>
                  <div class="dropdown-menu">
                    <label
                      v-for="role of roles"
                      :key="role.id"
                      class="dropdown-item"
                    >
                      <input class="form-check-input m-0 me-2" type="checkbox"
                        :checked="user.Role?.find(r => r.id === role.id)"
                        @change="toggleUserRole($event, user, role)" /> {{ role.role }}
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <button class="btn btn-danger" v-on:click="delUser(user.Email, user.ID)">Delete</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ul class="pagination mx-2 my-2" style="justify-content: end;" v-if="users.length > 0">
          <li class="page-item" :class="page !== 1 ? '' : 'disabled'">
            <a @click.prevent="prevPage" class="page-link" href="#" tabindex="-1" aria-disabled="true">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg>
            </a>
          </li>
          <li v-for="pageItem of pagesCount" :key="pageItem" :class="page ===
            pageItem ? 'active' : ''" class="page-item"><a @click.prevent="page = pageItem"
              class="page-link" href="#">{{pageItem}}</a></li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>
            </a>
          </li>
          <li class="page-item">
            <input v-model="perPage" type="number" min="1" style="height: 28px; width: 60px;" class="form-control">
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const $vue = getCurrentInstance()
const route = useRoute()
const router = useRouter()

const store = useStore()

const users = ref([])
const roles = ref([])

const search = computed({
  get: () => route.query.search || '',
  set (search) {
    if (typeof search !== 'string') return
    page.value = 1
    router.replace({ ...route, query: { ...route.query, search } })
  }
})
const usersToRender = computed(() => {
  const regex = new RegExp(`^.*${search.value}.*$`, 'i')
  return users.value
    .filter(u => u.Email?.match(regex) || u.Role?.some(r => r.role.match(regex)))
    .slice((page.value - 1) * perPage.value, page.value * perPage.value)
})
const pagesCount = computed(() => Math.ceil(users.value.length / perPage.value))
const perPage = computed({
  get: () => Number(route.query.perPage) || 10,
  set (perPage) {
    if (Number(perPage) < 1) return
    page.value = 1
    router.replace({ ...route, query: { ...route.query, perPage } })
  }
})
const page = computed({
  get: () => Number(route.query.page) || 1,
  set (page) {
    if (Number(page) < 1) return
    if (Number(page) > pagesCount.value) return
    router.replace({ ...route, query: { ...route.query, page } })
  }
})

function prevPage () {
  page.value = Number(page.value) - 1
}

function nextPage () {
  page.value = Number(page.value) + 1
}

function loadUsers () {
  store.dispatch('getUsers').then((data) => {
    users.value = data.Data
  })
}

function loadRoles () {
  store.dispatch('getRoles').then((data) => {
    roles.value = data.Data
  })
}

async function addRole (Email, Role) {
  await store.dispatch('setRole', { Email, Role })
  loadUsers()
}

async function delRole (email, role) {
  await store.dispatch('removeRole', { Email: email, Role: role })
  loadUsers()
}

async function delUser (name, id) {
  const res = await $vue?.proxy.$swal.fire({
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
    text: 'Do you really want to delete ' + name + '?'
  })
  if (!res.isConfirmed) {
    return
  }
  await store.dispatch('deleteUser', { ID: id })
  loadUsers()
}

function toggleUserRole (event, user, role) {
  if (event.target.checked) {
    addRole(user.Email, role.role)
  } else {
    delRole(user.Email, role.role)
  }
}

onMounted(async () => {
  loadUsers()
  loadRoles()
})
</script>

<style scoped>
.badge {
  color: var(--tblr-table-color-state, var(--tblr-table-color-type, var(--tblr-table-color)));
}
</style>
