import Axios from 'axios'
import store from '../store'

const BASE_URL = ''
const API_PREFIX = '/api'

export const api = Axios.create({
	baseURL: BASE_URL + API_PREFIX
})
api.defaults.withCredentials = true
api.interceptors.response.use((response) => {
	return response
}, (error) => {
	if (error?.response?.status === 403) {
		store.commit('logout')
	}
	return Promise.reject(error)
})
