import { api } from '.'

export async function getUsers () {
	const response = await api.get(
		'/admin/getusers'
	)
	return response.data
}
export async function getRoles () {
	const response = await api.get(
		'/admin/getroles'
	)
	return response.data
}

export async function setRole (data) {
	const response = await api.post(
		'/admin/setrole', data
	)
	return response.data
}
export async function removeRole (data) {
	const response = await api.post(
		'/admin/removerole', data
	)
	return response.data
}
export async function deleteUser (data) {
	const response = await api.post(
		'/admin/deleteuser', data
	)
	return response.data
}
export async function tokenCreate (data) {
	const response = await api.post(
		'/tokens', data
	)
	return response.data
}
export async function organizationCreate (data) {
	const response = await api.post(
		'/organizations', data
	)
	return response.data
}
export async function getTokens () {
	const response = await api.get('/tokens')
	return response.data
}
export async function getOrganizations () {
	const response = await api.get('/organizations')
	return response.data
}
export async function removeToken (data) {
	const response = await api.delete('/tokens' + '/' + data.ID, data)
	return response.data
}
