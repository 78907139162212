<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Security Logs</h3>
    </div>
    <div class="card-body">
      <div
        v-for="log of securityLogs"
        :key="log.id"
        class="row card card-body mb-2"
      >
        <div class="col">
          <div class="text-truncate">
            <strong>{{ log.log }}</strong>
          </div>
          <div class="text-muted" style="font-size: 10px;">
            Unknown IP address | Unknown location |
            <span :title="log.created_at">{{ formatDate(log.created_at) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from '@/api'
import moment from 'moment'
export default {
	data () {
		return {
			securityLogs: null
		}
	},
	methods: {
		formatDate (date) {
			return moment(date).calendar()
		},
		getLogs () {
			api.get('/securitylogs/').then((response) => {
				if (response.data.Status === 'ok') {
					this.securityLogs = response.data.Data
				}
			})
		}
	},
	mounted () {
		this.getLogs()
	}
}
</script>
