<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Account</h3>
      <div class="card-actions">
        <div class="card-actions btn-actions">
          <div
            style="cursor: pointer"
            @click="refreshAccountData()"
            class="btn-action"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
              <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-auto">
          <span
            class="avatar avatar-lg card-link"
            style="cursor: pointer"
            @click="uploadPhoto()"
            ><!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
            <img :src="getUserImage()" />
          </span>
          <input
            id="file-upload"
            name="upload_cont_img"
            type="file"
            style="display: none"
          />
        </div>
        <div class="col">
          <div class="">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-mail"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <rect x="3" y="5" width="18" height="14" rx="2"></rect>
                <polyline points="3 7 12 13 21 7"></polyline>
              </svg>
              Email: <strong>{{ user.Email }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { refreshToken } from '@/api/authentication'

import { api } from '@/api'
export default {
	data () {
		return {
		}
	},
	components: {},
	computed: {
		...mapState({
			user: (state) => state.user
		})
	},
	methods: {
		...mapMutations(['loginSuccess']),
		uploadPhoto () {
			const input = document.getElementById('file-upload')
			input.click()
			input.onchange = function () {
				const file = input.files[0]
				const formData = new FormData()
				formData.append('file', file)
				api.post('/user/image', formData).then(() => {
					document.location.reload()
				})
			}
		},
		getUserImage () {
			return api.defaults.baseURL + '/user/image'
		},
		refreshAccountData () {
			refreshToken().then((data) => {
				if (data.Status === 'ok') {
					this.loginSuccess(data.Data)
				}
			})
		}
	}
}
</script>
