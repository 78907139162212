import { createStore } from 'vuex'
import store from '.'
import {
	signup,
	signin,
	emailVerification,
	sendPasswordRecoveryCode,
	passwordRecovery,
	logout
} from '../api/authentication'
import {
	getUsers,
	getRoles,
	setRole,
	deleteUser,
	removeRole,
	tokenCreate,
	getTokens,
	removeToken,
	organizationCreate
} from '../api/admin'
import VuexPersistence from 'vuex-persist'
import router from '../router'

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	reducer: state => ({
		user: state.user
	})
})

function getPreferedTheme () {
	return (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light'
}

export default createStore({
	state: {
	theme: localStorage.getItem('data-bs-theme') || getPreferedTheme(),
	user: null,
		origin: null
	},
	mutations: {
		setTheme (state, theme) {
			window.setTheme(theme)
			state.theme = theme
		},
		toggleTheme (state) {
			const nextTheme = state.theme === 'dark' ? 'light' : 'dark'
			window.setTheme(nextTheme)
			state.theme = nextTheme
		},
		setOrigin (state, origin) {
			state.origin = origin
		},
		loginSuccess (state, user) {
			state.user = user
		},
		loginFailure (state) {
			state.user = null
		},
		logout (state) {
			state.user = null
			if (router.currentRoute.value.path !== '/origin') {
				router.push('/signin')
			}
		}
	},
	getters: {
		isSuperAdminRole: (state) => {
			return state.user?.Role?.find((x) => x.role === 'SUPER_ADMIN')
		},
		isInitRole: (state) => {
			return state.user?.Role?.find((x) => x.role === 'INIT.SKAARHOJ.COM') || store.getters.isSuperAdminRole
		}
	},
	actions: {
		getUsers (state) {
			return getUsers(state.user).then(
				data => {
					return Promise.resolve(data)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},
		sendPasswordRecoveryCode (_, email) {
			return sendPasswordRecoveryCode(email).then(
				data => {
					return Promise.resolve(data)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},

		passwordRecovery (_, data) {
			return passwordRecovery(data).then(
				data => {
					return Promise.resolve(data)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},

		setRole (_, data) {
			return setRole(data).then(
				data => {
					return Promise.resolve(data)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},

		removeRole (_, data) {
			return removeRole(data).then(
				data => {
					return Promise.resolve(data)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},

		deleteUser (_, data) {
			return deleteUser(data).then(
				data => {
					return Promise.resolve(data)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},
		getRoles (state) {
			return getRoles(state.user).then(
				data => {
					return Promise.resolve(data)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},
		signin (_, user) {
			return signin(user).then(
				data => {
					return Promise.resolve(data)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},
		logout ({ commit }) {
			logout()
			commit('logout')
		},
		emailVerification (_, code) {
			return emailVerification(code)
		},
		signup (_, user) {
			return signup(user).then(
				response => {
					return Promise.resolve(response.data)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},
		tokenCreate (_, name) {
			return tokenCreate(name).then(
				response => {
					return Promise.resolve(response)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},
		organizationCreate (_, name) {
			return organizationCreate(name).then(
				response => {
					return Promise.resolve(response)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},
		setOrigin ({ commit }, payload) {
			commit('setOrigin', payload)
		},
		getTokens () {
			return getTokens().then(
				response => {
					return Promise.resolve(response)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},
		removeToken (_, token) {
			return removeToken(token).then(
				response => {
					return Promise.resolve(response)
				},
				error => {
					return Promise.reject(error)
				}
			)
		}
	},
	modules: {},
	plugins: [vuexLocal.plugin]
})
