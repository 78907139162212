<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Authentication Keys</h3>
    </div>
    <div class="card-body p2">
      <div v-if="Keys">
        <div class="input-group mb-3" v-for="key of Keys" :key="key.ID">
          <input
            type="text"
            class="form-control"
            :placeholder="key.Name"
            disabled
          />
          <button
            class="btn btn-outline-danger"
            type="button"
            @click="remove(key)"
          >
            Remove
          </button>
        </div>
      </div>
      <button
        class="btn btn-outline-success"
        style="width: 100%"
        type="button"
        v-if="!createKey"
        @click="createKey = true"
      >
        Add new key
      </button>
      <div v-else>
        <span>Create new key</span>
        <input
          type="text"
          class="form-control mb-1 mt-2"
          placeholder="Enter Key Name..."
          v-model="Name"
        />
        <textarea
          style="min-height: 130px"
          placeholder="Enter Public Key Value.... "
          class="form-control mb-1"
          v-model="Key"
        ></textarea>
        <button
          class="btn btn-outline-success"
          style="width: 100%"
          type="button"
          @click="create"
        >
          Create key
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from '@/api'
export default {
	name: '',
	data () {
		return {
			Keys: null,
			createKey: false,
			Name: '',
			Key: ''
		}
	},
	components: {},
	computed: {},
	methods: {
		remove (key) {
			api
				.delete('/keys' + '/' + key.ID)
				.then((response) => {
					if (response.data.Status === 'ok') {
						this.fetchKeys()
					}
				})
				.catch((err) => {
					this.$swal.fire({
						icon: 'error',
						text: err.response.data.Data
					})
				})
		},
		create () {
			api
				.post('/keys', {
					Name: this.Name,
					Key: this.Key
				})
				.then((response) => {
					if (response.data.Status === 'ok') {
						this.createKey = false
						this.Name = ''
						this.Key = ''
						this.fetchKeys()
					}
				})
				.catch((err) => {
					this.$swal.fire({
						icon: 'error',
						text: err.response.data.Data
					})
				})
		},
		fetchKeys () {
			api
				.get('/keys')
				.then((response) => {
					if (response.data.Status === 'ok') {
						this.Keys = response.data.Data
					}
				})
				.catch((err) => {
					this.$swal.fire({
						icon: 'error',
						text: err.response.data.Data
					})
				})
		}
	},
	mounted () {
		this.fetchKeys()
	}
}
</script>
<style scoped>
input.form-control {
  background-color: #fff;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(55, 55, 55) !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(55, 55, 55) !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgb(55, 55, 55) !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(55, 55, 55) !important;
}
</style>
