<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Organizations</h3>
    </div>
    <div class="card-body p2">
      <div class="row">
        <div
          class="col col-3"
          v-for="organization of user.Organizations"
          :key="organization.name"
        >
          <a class="mt-3 card card-link card-body" @click="routeTo(organization)" style="cursor:pointer">
            <div
              class="form-selectgroup-label-content d-flex align-items-center"
            >
              <span
                class="avatar me-3"
                :style="'background-image: url(' + orgImage(organization) + ')'"
              ></span>
              <div>
                <div class="font-weight-medium">{{ organization.name }}</div>
                <span
                  class="badge bg-blue-lt"
                  style="margin-right: 5px; margin-top: 10px"
                  v-for="role of organization.roles"
                  :key="role.role"
                  >{{ role.role }}</span
                >
              </div>
              <div></div>
            </div>
          </a>
        </div>
      </div>
      <button
        class="btn btn-outline-success mt-2"
        style="width: 100%"
        type="button"
        v-if="!createOrganization"
        @click="createOrganization = true"
      >
        Add new organization
      </button>
      <div v-else>
        <span>Create new Organization</span>
        <input
          type="text"
          class="form-control mb-1 mt-2"
          placeholder="Enter Organization Name..."
          v-model="organizationName"
        />
        <button
          class="btn btn-outline-success"
          style="width: 100%"
          type="button"
          @click="initOrganizationCreate()"
        >
          Create Organization
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { api } from '@/api'
import { refreshToken } from '@/api/authentication'

export default {
	data () {
		return {
			createOrganization: false,
			organizationName: '',
			organizations: []
		}
	},
	computed: {
		...mapState({
			user: (state) => state.user
		})
	},
	methods: {
		...mapActions(['organizationCreate']),
		...mapMutations(['loginSuccess']),
		routeTo (org) {
			this.$router.push('/settings/organization/' + org.id)
		},
		refreshAccountData () {
			refreshToken().then((data) => {
				if (data.Status === 'ok') {
					this.loginSuccess(data.Data)
					this.organizationName = ''
					this.createOrganization = false
				}
			})
		},
		orgImage (organization) {
			return api.defaults.baseURL + '/organizations/' + organization.id + '/image'
		},
		initOrganizationCreate () {
			this.organizationCreate({ Name: this.organizationName }).then((data) => {
				if (data.Status === 'ok') {
					this.refreshAccountData()
				}
			})
		}
	}
}
</script>
