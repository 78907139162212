<template>
  <div></div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { refreshToken } from '@/api/authentication'

export default {
	name: 'Origin',
	computed: {
		...mapState({
			user: state => state.user,
			origin: state => state.origin
		})
	},
	methods: {
		...mapActions(['setOrigin'])
	},
	async mounted () {
		try {
			await refreshToken()
			window.location.replace('//' + this.$route.query.origin + '/sso')
		} catch (e) {
			this.setOrigin(this.$route.query.origin)
			this.$router.push('/signin')
		}
	}
}
</script>
