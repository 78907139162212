<template>
  <div class="container-tight">
    <div
      style="
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      "
    >
      <div style="display: flex;  justify-content: center; width: 100%">
        <img src="/logo.png" height="25" class="mb-4" />
      </div>
      <div class="card card-md" style="min-width: 450px">
        <div class="card-body">
          <h2 class="card-title mb-4">Create new account</h2>
          <div class="mb-3">
            <label class="form-label" style="text-align: left">
              Email address
            </label>
            <input
              v-model="user.email"
              @keyup.enter="initSignup()"
              type="email"
              class="form-control"
              placeholder="Enter email"
            />
          </div>
          <div class="mb-3">
            <label class="form-label" style="text-align: left">Password</label>
            <input
              @keyup.enter="initSignup()"
              v-model="user.password"
              type="password"
              class="form-control"
              placeholder="Password"
              autocomplete="off"
            />
          </div>
          <vue-recaptcha
            class="g-recaptcha"
            :sitekey="sitekey"
            :loadRecaptchaScript="true"
            @verify="verify"
            @expired="expired"
          ></vue-recaptcha>
          <div class="form-footer">
            <button
              :disabled="!user.email || !user.password || isRobot"
              type="submit"
              class="btn btn-primary w-100"
              @keyup.enter="initSignup()"
              @click="initSignup()"
            >
              Create new account
            </button>
          </div>
        </div>
      </div>
      <div class="text-center text-muted mt-3">
        Already have account? <router-link to="/signin"> Sign in </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
	name: 'Signup',
	components: {
		VueRecaptcha
	},
	data () {
		return {
			sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
			user: {
				password: null,
				email: null,
				recaptcha: null
			},
			isRobot: true
		}
	},
	methods: {
		...mapActions(['signup']),
		verify (response) {
			if (response) {
				this.isRobot = false
				this.user.recaptcha = response
			} else {
				this.isRobot = true
				this.user.recaptcha = null
			}
		},
		expired () {
			this.isRobot = true
			this.user.recaptcha = null
		},
		initSignup () {
			if (this.isRobot) {
				this.$swal.fire({
					icon: 'error',
					text: 'Please verify that you are not a robot!'
				})
				return
			}
			if (this.user.password.length < 8) {
				this.$swal.fire({
					icon: 'error',
					text: 'Password should be at least 8 characters.'
				})
				return
			}
			if (this.user.email && this.user.password) {
				this.signup(this.user)
					.then(() => {
						this.$router.push('/email-verification')
					})
					.catch((err) => {
						this.$swal.fire({
							icon: 'error',
							text: err.response.data.Data
						})
					})
			} else {
				this.$swal.fire({
					icon: 'error',
					text: 'Please make sure to provide email and password.'
				})
			}
		}
	}
}
</script>
<style scoped>
.g-recaptcha {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
</style>
