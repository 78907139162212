<template>
  <div class="container-tight">
    <div
      style="
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      "
    >
      <div style="display: flex; justify-content: center; width: 100%">
        <img src="/logo.png" height="25" class="mb-4" />
      </div>
      <div v-if="!isCodeSent">
        <div class="card card-md" style="min-width: 450px">
          <div class="card-body">
            <h2 class="card-title text-center mb-4">Forgot password</h2>
            <div class="mb-3">
              <label class="form-label" style="text-align: left"
                >Email address</label
              >
              <input
                v-model="Email"
                type="text"
                class="form-control"
                placeholder="Email address"
                autocomplete="off"
              />
            </div>
            <div class="form-footer">
              <button
                :disabled="!Email"
                type="submit"
                class="btn btn-primary w-100"
                @click="initSendCode()"
              >
                Recover
              </button>
            </div>
          </div>
        </div>
        <div class="text-center text-muted mt-3">
          I know the password <router-link to="/signin"> Sign in </router-link>
        </div>
      </div>
      <div class="card card-md" style="min-width: 450px" v-else>
        <div class="card-body">
          <h2 class="card-title text-center mb-4">
            Enter your Code and Password
          </h2>
          <div class="mb-3">
            <label class="form-label" style="text-align: left">Code</label>
            <input
              v-model="Code"
              type="text"
              class="form-control"
              placeholder="Code received by email"
              autocomplete="off"
            />
          </div>
          <div class="mb-3">
            <label class="form-label" style="text-align: left">Password</label>
            <input
              v-model="Password"
              type="password"
              class="form-control"
              placeholder="New password"
              autocomplete="off"
            />
          </div>
          <div class="mb-3">
            <label class="form-label" style="text-align: left"
              >Repeat password</label
            >
            <input
              v-model="RepeatPassword"
              type="password"
              class="form-control"
              placeholder="Repeat new password"
              autocomplete="off"
            />
          </div>
          <span style="color: red" v-if="Password && Password != RepeatPassword"
            >Passwords doesn't match</span
          >
          <div class="form-footer">
            <button
              :disabled="
                !Code ||
                !Password ||
                !RepeatPassword ||
                Password != RepeatPassword
              "
              type="submit"
              class="btn btn-primary w-100"
              @click="initPasswordRecovery()"
            >
              Recover
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'PasswordRecovery',
	data () {
		return {
			Email: null,
			Code: null,
			Password: null,
			RepeatPassword: null,
			isCodeSent: false
		}
	},
	methods: {
		...mapActions(['sendPasswordRecoveryCode', 'passwordRecovery']),
		initSendCode () {
			if (this.Email) {
				this.sendPasswordRecoveryCode({ Email: this.Email })
					.then(() => {
						this.isCodeSent = true
					})
					.catch((err) => {
						this.$swal.fire({
							icon: 'error',
							text: err.response.data.Data
						})
					})
			}
		},
		initPasswordRecovery () {
			if (this.Email && this.Password === this.RepeatPassword && this.Code) {
				this.passwordRecovery({
					Email: this.Email,
					Password: this.Password,
					Code: this.Code
				})
					.then(() => {
						this.$router.push('/signin')
					})
					.catch((err) => {
						this.$swal.fire({
							icon: 'error',
							text: err.response.data.Data
						})
					})
			}
		}
	}
}
</script>
