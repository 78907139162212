<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Two Factor Authentication</h3>
      <div class="card-actions form-group" style="display: flex;">
        <button class="btn btn-outlined-primary" v-if="Status" @click="showCodes()">Show codes</button>
        <label class="form-check form-check-single form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="checkbox"
            @change="handleCheckbox()"
            :checked="Status"
          />
        </label>
      </div>
    </div>
    <div class="card-body p2" v-if="!Status && Secret">
      <div v-if="Secret">
        <p>Scan the QrCode below to enable Two Factor Authentication:</p>
        <QrcodeVue
          :value="
            'otpauth://totp/' +
            user.Email +
            '?secret=' +
            Secret +
            '&issuer=SKAARHOJ'
          "
          :size="150"
        />
        <br />
        Or enter the secret manually in your authenticator:
        <pre>{{ Secret }}</pre>
        Enter the code from your authenticator:
        <div class="input-group mb-1">
          <input type="text" class="form-control" v-model="Code" />
          <button
            class="btn btn-outline-danger"
            type="button"
            @click="cancel()"
          >
            Cancel
          </button>
          <button
            class="btn btn-outline-success"
            type="button"
            @click="enable()"
          >
            Enable
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="RecoveryCodes" class="card-body p2">
      <span
        >These are your two factor authentication recovery codes and they can be
        used in situations where you have lost access to your
        authenticator.</span
      ><br /><br />
      <strong>Please copy and keep codes in a secure place!</strong>
      <pre>
    <div class="row">
    <div class="col" style="text-align:center;"><div v-for="i in 5" :key="i"><span>{{RecoveryCodes[i-1].is_used ? 'ALREADY USED' : RecoveryCodes[i-1].code}}</span></div></div>
    <div class="col" style="text-align:center;"><div v-for="i in 5" :key="i+4"><span>{{RecoveryCodes[i+4].is_used ? 'ALREADY USED' : RecoveryCodes[i+4].code}}</span></div></div>
    </div>
    </pre>
      <button class="btn btn-success" style="float: right" @click="hideCodes()">
        Hide Codes
      </button>
    </div>
  </div>
</template>
<script>
import { api } from '@/api'
import QrcodeVue from 'qrcode.vue'
import { mapState } from 'vuex'

export default {
	data () {
		return {
			Secret: '',
			Status: null,
			Code: '',
			RecoveryCodes: null
		}
	},
	components: {
		QrcodeVue
	},
	computed: {
		...mapState({
			user: (state) => state.user
		})
	},
	methods: {
		showCodes () {
			api
				.get('/twofa/codes')
				.then((response) => {
					if (response.data.Status === 'ok') {
						this.RecoveryCodes = response.data.Data
					}
				})
				.catch((err) => {
					this.$swal.fire({
						icon: 'error',
						text: err.response.data.Data
					})
				})
		},
		hideCodes () {
			this.RecoveryCodes = null
		},
		handleCheckbox () {
			if (this.Status && !this.Secret) {
				this.disable()
			} else if (!this.Status && !this.Secret) {
				this.fetchSecret()
			} else if (!this.Status && this.Secret) {
				this.disable()
			}
		},
		cancel () {
			this.fetchStatus()
			this.Secret = ''
			this.Code = ''
		},
		disable () {
			api
				.delete('/twofa')
				.then((response) => {
					if (response.data.Status === 'ok') {
						this.cancel()
					}
				})
				.catch((err) => {
					this.$swal.fire({
						icon: 'error',
						text: err.response.data.Data
					})
				})
		},
		enable () {
			api
				.post('/twofa', {
					Code: this.Code
				})
				.then((response) => {
					if (response.data.Status === 'ok') {
						this.RecoveryCodes = response.data.Data
						this.cancel()
					}
				})
				.catch((err) => {
					this.$swal.fire({
						icon: 'error',
						text: err.response.data.Data
					})
				})
		},
		fetchSecret () {
			api
				.get('/twofa')
				.then((response) => {
					if (response.data.Status === 'ok') {
						this.Secret = response.data.Data
					}
				})
				.catch((err) => {
					this.$swal.fire({
						icon: 'error',
						text: err.response.data.Data
					})
				})
		},
		fetchStatus () {
			api
				.get('/twofa/status')
				.then((response) => {
					if (response.data.Status === 'ok') {
						this.Status = response.data.Data
						document.getElementById('checkbox').checked = response.data.Data
					}
				})
				.catch((err) => {
					this.$swal.fire({
						icon: 'error',
						text: err.response.data.Data
					})
				})
		}
	},
	mounted () {
		this.fetchStatus()
	}
}
</script>
