<template>
  <div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'EmailVerificationCode',
	methods: {
		...mapActions(['emailVerification']),
		initEmailVerification () {
			if (this.$route.params.code) {
				this.emailVerification(this.$route.params.code)
					.then(() => {
						this.$router.push('/signin')
					})
					.catch((err) => {
						this.$swal.fire({
							icon: 'error',
							text: err.response.data.Data
						})
					})
			}
		}
	},
	mounted () {
		this.initEmailVerification()
	}
}
</script>
