<template>
  <div class="container-tight">
   <div
      style="
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      "
    >
    <div style="display: flex;  justify-content: center; width: 100%">
      <img src="/logo.png" height="25" class="mb-4" />
    </div>
    <div class="card card-md">
      <div class="card-body">
        <h2 class="card-title text-center mb-4">Enter Verification code that you have received by Email</h2>
        <div class="mb-3">
          <label class="form-label" style="text-align: left;">Verification Code</label>
          <input
            v-model="code"
            type="text"
            class="form-control"
            placeholder="Verification Code"
            autocomplete="off"
          />
        </div>
        <div class="form-footer">
          <button type="submit" class="btn btn-primary w-100" @click="initEmailVerification()">
            Verify
          </button>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'EmailVerification',
	data () {
		return {
			code: ''
		}
	},
	methods: {
		...mapActions(['emailVerification']),
		initEmailVerification () {
			if (this.code) {
				this.emailVerification(this.code)
					.then(() => {
						this.$router.push('/signin')
					})
					.catch((err) => {
						this.$swal.fire({
							icon: 'error',
							text: err.response.data.Data
						})
					})
			}
		}
	}
}
</script>
