<template>
  <div>
    <div
      id="editUser"
      tabindex="-1"
      style="display: none"
      class="modal modal-blur fade"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="selectedUser">
              Edit {{ selectedUser.email }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" v-if="selectedUser">
            <div class="mb-1">
              <label class="form-label">Position</label>
              <div class="btn-group w-100">
                <button
                  @click="changeRole({ ID: 1, role: 'OWNER' })"
                  type="button"
                  class="btn"
                  :class="
                    selectedUser.roles[0].role === 'OWNER' ? 'btn-primary' : ''
                  "
                >
                  OWNER
                </button>
                <button
                  @click="changeRole({ ID: 2, role: 'USER' })"
                  type="button"
                  class="btn"
                  :class="
                    selectedUser.roles[0].role === 'USER' ? 'btn-primary' : ''
                  "
                >
                  USER
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-danger" @click="deleteUser(selectedUser)">Delete</button>
            <button type="button" class="btn" @click="close()">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="organization">
      <div class="card-header">
        <h3 class="card-title">{{ organization.details.name }}</h3>
      </div>
      <div class="card-body p2">
        <div class="datagrid">
          <div class="datagrid-item">
            <div class="datagrid-title">Owner</div>
            <div class="datagrid-content">
              <div class="d-flex align-items-center">
                <span
                  class="avatar avatar-xs me-2 avatar-rounded"
                  :style="
                    'background-image: url(' + organization.owner.image + ')'
                  "
                ></span>
                {{ organization.owner.email.split("@")[0] }}
              </div>
            </div>
          </div>
          <div class="datagrid-item">
            <div class="datagrid-title">Created</div>
            <div class="datagrid-content">
              {{ formatDate(organization.details.created_at) }}
            </div>
          </div>
          <div class="datagrid-item">
            <div class="datagrid-title">Users</div>
            <div class="datagrid-content">
              <div class="avatar-list avatar-list-stacked">
                <span
                  v-for="orgUser of organization.users.slice(
                    0,
                    organization.users.length >= 3
                      ? 3
                      : organization.users.length
                  )"
                  :key="orgUser.id"
                  class="avatar avatar-xs avatar-rounded"
                  :style="'background-image: url(' + orgUser.image + ')'"
                ></span>
                <span
                  class="avatar avatar-xs avatar-rounded"
                  v-if="organization.users.length > 3"
                  >+{{ organization.users.length - 3 }}</span
                >
              </div>
            </div>
          </div>
          <div class="datagrid-item">
            <div class="datagrid-title">Position</div>
            <div class="datagrid-content">
              <span
                v-for="position of organizationPositions"
                :key="position.role + '-' + position.id"
                >{{ position.role }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="accordion" role="tablist" aria-multiselectable="true">
          <div class="accordion-item">
            <div class="accordion-header" role="tab">
              <button
                class="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#faq-1-1"
                aria-expanded="false"
              >
                Invites ({{ invites ? invites.length : 0 }})
              </button>
            </div>
            <div
              id="faq-1-1"
              class="accordion-collapse collapse"
              role="tabpanel"
              data-bs-parent="#faq-1"
              style=""
            >
              <div class="accordion-body pt-0">
                <form class="input-group mb-2" @submit="invite">
                  <input
                    type="text"
                    class="form-control"
                    v-model="inviteEmail"
                    placeholder="Enter email..."
                  />
                  <button class="btn" type="submit">
                    Send invitation
                  </button>
                </form>
                <div class="row">
                  <div
                    class="col col-6"
                    v-for="invite of invites"
                    :key="invite.id"
                  >
                    <div class="card mb-3">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-auto">
                            <span
                              class="avatar rounded"
                              :style="
                                'background-image: url(' + invite.image + ')'
                              "
                            ></span>
                          </div>
                          <div class="col">
                            <div class="font-weight-medium">
                              {{ invite.email }}
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="card-actions btn-actions">
                              <div
                                class="btn-action vertical-center"
                                style="cursor: pointer"
                              >
                                <!-- Download SVG icon from http://tabler-icons.io/i/x -->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="icon icon-tabler icon-tabler-settings"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="2"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path
                                    d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
                                  ></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" role="tab">
              <button
                class="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#faq-1-2"
                aria-expanded="false"
              >
                Members ({{ organization.users.length }})
              </button>
            </div>
            <div
              id="faq-1-2"
              class="accordion-collapse collapse"
              role="tabpanel"
              data-bs-parent="#faq-1"
            >
              <div class="accordion-body pt-0">
                <div class="row">
                  <div
                    class="col col-6"
                    v-for="orgUser of organization.users"
                    :key="orgUser.id"
                  >
                    <div class="card mb-3">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-auto">
                            <span
                              class="avatar rounded"
                              :style="
                                'background-image: url(' + orgUser.image + ')'
                              "
                            ></span>
                          </div>
                          <div class="col">
                            <div class="font-weight-medium">
                              {{ orgUser.email }}
                            </div>
                            <div class="text-muted">
                              <span
                                v-for="role of orgUser.roles"
                                :key="
                                  role.role + '-' + orgUser.id + '-' + role.id
                                "
                                >{{ role.role }}</span
                              >
                            </div>
                          </div>
                          <div
                            class="col-auto"
                            v-if="organization.owner.email === user.Email"
                          >
                            <div class="card-actions btn-actions">
                              <div
                                @click="edit(orgUser)"
                                class="btn-action vertical-center"
                                style="cursor: pointer"
                              >
                                <!-- Download SVG icon from http://tabler-icons.io/i/x -->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="icon icon-tabler icon-tabler-settings"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="2"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path
                                    d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
                                  ></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import moment from 'moment'
import { api } from '@/api'
import $ from 'jquery'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import store from '@/store'

const $vue = getCurrentInstance()
const route = useRoute()

const organization = ref(null)
const organizationID = ref(route.params.id)
const selectedUser = ref(null)
const inviteEmail = ref('')
const invites = ref([])

const user = computed(() => store.state.user)

const organizationPositions = computed(() => {
  const org = user.value?.Organizations?.find(
    (org) => org.id === parseInt(organizationID.value)
  )
  if (org) {
    return org.roles
  }
  return null
})

function getInvites () {
  api
    .get(`/organizations/${organizationID.value}/invites`)
    .then((response) => {
      if (response.data.Status === 'ok') {
        invites.value = response.data.Data
      }
    })
}

function invite (e) {
  e.preventDefault()
  api.post('/organizations/' + organizationID.value + '/users/invite', {
    email: inviteEmail.value.replaceAll(' ', '')
  }).then(() => {
    inviteEmail.value = ''
    getInvites()
  }).catch(e => {
    $vue?.proxy.$swal('Error', e.response.data.Data, 'error')
  })
}

function changeRole (role) {
  api
    .post(
      '/organizations/' +
        organizationID.value +
        '/users/' +
        selectedUser.value.id +
        '/role',
      role
    )
    .then(() => {
      selectedUser.value = { ...selectedUser.value, roles: [role] }
      loadOrganizationData()
    })
    .catch(e => {
      $vue?.proxy.$swal('Error', e.response.data.Data, 'error')
    })
}

function loadOrganizationData () {
  api.get('/organizations/' + organizationID.value).then((response) => {
    if (response.data.Status === 'ok') {
      organization.value = response.data.Data
    }
  })
}

function edit (user) {
  selectedUser.value = user
  $('#editUser').modal('show')
}

async function deleteUser (user) {
  const confirm = await $vue?.proxy.$swal.fire({
    title: 'Are you sure?',
    text: `You are about to delete user ${user.email}. Are you sure?`,
    showCancelButton: true,
    confirmButtonText: 'Confirm'
  })

  if (!confirm.isConfirmed) return

  api.delete(`/organizations/${organizationID.value}/users/${user.id}`)
    .then(() => {
      loadOrganizationData()
      close()
    })
    .catch(e => $vue?.proxy.$swal('Error', e.response.data.Data, 'error'))
}

function close () {
  $('#editUser').modal('hide')
  selectedUser.value = null
}

function formatDate (date) {
  return moment(date).calendar()
}

onMounted(() => {
	loadOrganizationData()
	getInvites()
})
</script>
<style scoped>
.datagrid {
  --tblr-datagrid-padding: 1.5rem;
  --tblr-datagrid-item-width: 15rem;
  display: grid;
  grid-gap: var(--tblr-datagrid-padding);
  grid-template-columns: repeat(
    auto-fit,
    minmax(var(--tblr-datagrid-item-width), 1fr)
  );
}

.datagrid-title {
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  line-height: 1.6;
  color: #626976;
  margin-bottom: 0.25rem;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}
.btn-action {
  padding: 0;
  border: 0;
  color: #626976;
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.btn-action.show,
.btn-action:hover {
  color: #1e293b;
  background: #f5f7fb;
}
.card-actions {
  margin: -0.5rem -0.5rem -0.5rem auto;
  padding-left: 0.5rem;
}
.btn-actions {
  display: flex;
}
</style>
