<template>
  <div class="page-body">
    <div class="container-xl">
      <div class="page-header mb-3">
        <div class="row align-items-center mw-100">
          <div class="col">
            <h2 class="page-title">
              <span class="text-truncate">Settings</span>
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 px-4">
          <div class="list-group list-group-transparent mb-3 ml-3">
            <router-link
              to="/settings"
              class="
                list-group-item list-group-item-action
                d-flex
                align-items-center
              "
              :class="$route.name === 'SettingsAccount' ? 'active' : ''"
            >
              Account
            </router-link>
            <router-link
              v-if="isInitRole"
              to="/settings/keys"
              class="
                list-group-item list-group-item-action
                d-flex
                align-items-center
              "
              :class="$route.name === 'SettingsKeys' ? 'active' : ''"
            >
              Authentication Keys
            </router-link>
            <router-link
              v-if="isInitRole"
              to="/settings/tokens"
              class="
                list-group-item list-group-item-action
                d-flex
                align-items-center
              "
              :class="$route.name === 'SettingsTokens' ? 'active' : ''"
            >
              Authentication Tokens
            </router-link>
            <router-link
              to="/settings/twofa"
              class="
                list-group-item list-group-item-action
                d-flex
                align-items-center
              "
              :class="$route.name === 'SettingsTwoFa' ? 'active' : ''"
            >
              Two-factor authentication
            </router-link>
            <router-link
              to="/settings/organizations"
              class="
                list-group-item list-group-item-action
                d-flex
                align-items-center
              "
              :class="
                ['SettingsOrganizations', 'SettingsOrganizationSettings'].includes($route.name)
                  ? 'active'
                  : ''
              "
            >
              Organizations
            </router-link>
            <router-link
              to="/settings/securitylogs"
              class="
                list-group-item list-group-item-action
                d-flex
                align-items-center
              "
              :class="$route.name === 'SettingsSecurityLogs' ? 'active' : ''"
            >
              Security Logs
            </router-link>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="row">
            <div class="col-12">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
	computed: {
		...mapState({
			user: (state) => state.user
		}),
		...mapGetters(['isInitRole'])
	}
}
</script>
